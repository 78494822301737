import { commonHelpers } from "@/utils/helpers";

import { useRouter } from "next/router";

const useAppRouter = () => {
  const router = useRouter();
  const query = router.query as any;
  const queryPreviewMode = Array.isArray(router.query.preview_mode)
    ? router.query.preview_mode[0]
    : router.query.preview_mode;

  const push: typeof router.push = async (url, as, options) => {
    if (typeof window === "undefined")
      return await router.push(url, as, options);
    const curUrl = new URL(window.location.href);
    const previewMode =
      queryPreviewMode ?? curUrl.searchParams.get("preview_mode");
    if (typeof url === "string" && !commonHelpers.isValidURL(url)) {
      const tempUrl = new URL(`${window.location.origin}${url}`);
      if (previewMode === "enabled") {
        tempUrl.searchParams.set("preview_mode", "enabled");
      }
      return await router.push(tempUrl.pathname, as, options);
    }
    if (typeof url === "object") {
      const tempQuery = { ...(url.query as any) };
      const tempSearch = url.search || "";
      const tempSearchParams = new URLSearchParams(tempSearch);
      const tempSearchQuery = Object.fromEntries(tempSearchParams.entries());
      if (previewMode === "enabled") {
        Object.assign(tempQuery, {
          ...tempSearchQuery,
          preview_mode: "enabled",
        });
      }

      return await router.push(
        {
          ...url,
          search: "",
          query: tempQuery,
        },
        as,
        options
      );
    }
    return await router.push(url, as, options);
  };

  const replace: typeof router.replace = async (url, as, options) => {
    if (typeof window === "undefined")
      return await router.replace(url, as, options);
    const curUrl = new URL(window.location.href);
    const previewMode =
      queryPreviewMode ?? curUrl.searchParams.get("preview_mode");
    if (typeof url === "string" && !commonHelpers.isValidURL(url)) {
      const tempUrl = new URL(`${window.location.origin}${url}`);
      if (previewMode === "enabled") {
        tempUrl.searchParams.set("preview_mode", "enabled");
      }
      return await router.replace(tempUrl.pathname, as, options);
    }
    if (typeof url === "object") {
      const tempQuery = { ...(url.query as any) };
      const tempSearch = url.search || "";
      const tempSearchParams = new URLSearchParams(tempSearch);
      const tempSearchQuery = Object.fromEntries(tempSearchParams.entries());

      if (previewMode === "enabled") {
        Object.assign(tempQuery, {
          ...tempSearchQuery,
          preview_mode: "enabled",
        });
      }

      return await router.replace(
        {
          ...url,
          search: "",
          query: tempQuery,
        },
        as,
        options
      );
    }
    return await router.replace(url, as, options);
  };

  return {
    ...router,
    push,
    replace,
  };
};

export default useAppRouter;
