declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import { forwardRef, useMemo } from "react";

import NextLink from "next/link";
import { useRouter } from "next/router";

import { Link } from "@mui/material";

import type { LinkProps } from "@mui/material";
import type { LinkProps as NextLinkProps } from "next/link";
import { commonHelpers } from "@/utils/helpers";

export type AppLinkProps<
  D extends React.ElementType = "a",
  P = {}
> = NextLinkProps & Omit<LinkProps<D, P>, keyof NextLinkProps>;

const AppLink = <D extends React.ElementType = "a", P = {}>(
  props: AppLinkProps<D, P>,
  ref: React.ForwardedRef<any>
) => {
  const { href: controlledHref = "", ...rest } = props;

  const router = useRouter();
  const query = router.query as any;
  const queryPreviewMode = Array.isArray(router.query.preview_mode)
    ? router.query.preview_mode[0]
    : router.query.preview_mode;

  const href = useMemo(() => {
    if (typeof window === "undefined") return controlledHref;
    const curUrl = new URL(window.location.href);
    const previewMode =
      queryPreviewMode ?? curUrl.searchParams.get("preview_mode");

    if (
      typeof controlledHref === "string" &&
      !commonHelpers.isValidURL(controlledHref)
    ) {
      const url = new URL(`${window.location.origin}${controlledHref}`);
      if (previewMode === "enabled") {
        url.searchParams.set("preview_mode", "enabled");
      }
      return `${url.pathname}${url.search}`;
    }
    if (typeof controlledHref === "object") {
      const tempQuery = { ...(controlledHref.query as any) };
      const tempSearch = controlledHref.search || "";
      const tempSearchParams = new URLSearchParams(tempSearch);
      const tempSearchQuery = Object.fromEntries(tempSearchParams.entries());
      if (previewMode === "enabled") {
        Object.assign(tempQuery, {
          ...tempSearchQuery,
          preview_mode: "enabled",
        });
      }
      return {
        ...controlledHref,
        search: "",
        query: tempQuery,
      };
    }
    return controlledHref;
  }, [controlledHref, queryPreviewMode]);

  return (
    <Link
      ref={ref}
      underline="none"
      color="initial"
      component={NextLink}
      href={href as any}
      {...rest}
    />
  );
};

const AppLinkWithRef = forwardRef(AppLink);

export default AppLinkWithRef;
