import moment from "moment";
import { toast } from "react-toastify";
import _round from "lodash/round";
import { commonConfig } from "@/utils/config";

export const isMobile = () => {
  return (
    typeof window !== "undefined" &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );
};

export const isIOS = () => {
  return (
    typeof window !== "undefined" &&
    ([
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
      navigator.userAgent.includes("Mac"))
  );
};

export const isEmpty = (value: any) => {
  return (
    (Array.isArray(value) && value.length === 0) ||
    ["", null, undefined].includes(value)
  );
};

export const isEmptyEvery = (value: any[]) => {
  return value.every((val) => {
    return (
      (Array.isArray(val) && val.length === 0) ||
      ["", null, undefined].includes(val)
    );
  });
};

export const isEmptySome = (value: any[]) => {
  return value.some((val) => {
    return (
      (Array.isArray(val) && val.length === 0) ||
      ["", null, undefined].includes(val)
    );
  });
};

export const isNumber = (number: any) => {
  return !isEmpty(number) && !isNaN(Number(number));
};

export const formatNumber = (
  number?: number | string,
  options?: Intl.NumberFormatOptions
) => {
  if (!isNumber(number)) return number;
  const locale = window.NextPublic.lang;
  return new Intl.NumberFormat(locale, options).format(Number(number));
};

export const decodeHTML = (input: string) => {
  const e = document.createElement("textarea");
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue || "";
};

export const formatFormData = (data: Object) => {
  const fd = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === "undefined") return;
    if (Array.isArray(value) && value.some((v) => v instanceof File)) {
      fd.append(`${key}[]`, value as any);
    } else {
      fd.append(
        key,
        typeof value === "string" || value instanceof File
          ? value
          : JSON.stringify(value)
      );
    }
  });
  return fd;
};

export const checkAndNoticeToastError = (error: string, loading: boolean) => {
  if (!!error && !loading) toast.error(error);
};

export const parseStyles = (stringStyles: string | React.CSSProperties) =>
  typeof stringStyles === "string"
    ? stringStyles.split(";").reduce((acc, style) => {
        const colonPosition = style.indexOf(":");

        if (colonPosition === -1) {
          return acc;
        }

        const camelCaseProperty = style
            .substr(0, colonPosition)
            .trim()
            .replace(/^-ms-/, "ms-")
            .replace(/-./g, (c) => c.substr(1).toUpperCase()),
          value = style.substr(colonPosition + 1).trim();

        return value ? { ...acc, [camelCaseProperty]: value } : acc;
      }, {})
    : {};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const momentWithLocale = moment.locale(
  typeof window === "undefined" ? "en-US" : window.NextPublic.lang
);

export const formatFileSize = (fileSize: number) => {
  return fileSize > 1000000
    ? `${_round(fileSize / 1000000, 1)}MB`
    : `${_round(fileSize / 1000, 1)}KB`;
};

export const formatPhoneNumberLink = (fontNumber: string | number) => {
  return (`${fontNumber}`.match(/[\d|\+]+/g) ?? [])?.join("");
};

export const showServerGMT = () => {
  const localeGMT = moment().format("Z");
  return localeGMT === commonConfig.DEFAULT_GMT ? "" : commonConfig.DEFAULT_GMT;
};

export const downloadImages = async (
  images: {
    src: string;
    name?: string;
  }[]
) => {
  const preparedImages = images.map((img) => {
    let name = img.name;
    if (!name) {
      name = img.src.split("/").reverse()[0];
    }
    return {
      src: img.src,
      name,
    };
  });

  try {
    for (const preparedImage of preparedImages) {
      const response = await fetch(preparedImage.src);

      const blobImage = await response.blob();

      const href = URL.createObjectURL(blobImage);

      const anchorElement = document.createElement("a");
      anchorElement.href = href;
      anchorElement.download = preparedImage.name;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    }
  } catch (error: any) {
    const message = error?.response?.data || error.message;
    return {
      status: false,
      message,
    };
  }

  return {
    status: true,
  };
};

export const isValidURL = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    return ["http:", "https:"].includes(parsedUrl.protocol);
  } catch {
    return false;
  }
};
